import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../users.service";
import { KeyMap, User } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxIconComponent } from "src/app/components/shared/zx-icon/zx-icon.component";
import { assignIconInputsFactory } from "src/app/components/shared/zx-icon/zx-icon.table-adapter";
import { TourSteps } from "src/app/constants/tour-steps";
import { TourService } from "ngx-ui-tour-md-menu";
import { DatePipe } from "@angular/common";

@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html",
    providers: [DatePipe]
})
export class UserListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    users: User[] = [];
    selectedUserID: number;
    selectedRows: User[] = [];
    isAdmin: boolean;
    urls = Constants.urls;

    impersonating$: Observable<boolean>;
    isZixiAdmin$: Observable<number>;

    private routeSubscription: Subscription;
    private usersSubscription: Subscription;

    private datePipe = inject(DatePipe);

    private usersBS$ = new BehaviorSubject<User[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<User>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: User): string => row.name,
            textValue: (row: User): string => row.name,
            valueToExport: (row: User): string => row.name
        },
        {
            header: this.translate.instant("EMAIL"),
            columnDef: "email",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<User>(
                row => row.email,
                row => row.email,
                () => true
            ),
            sortBy: (row: User): string => row.email,
            textValue: (row: User): string => row.email,
            valueToExport: (row: User): string => row.email
        },
        {
            header: this.translate.instant("SSO"),
            columnDef: "sso",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<User>(
                row => row.SSO?.name ?? "-",
                row => row.SSO?.name ?? "-",
                () => true
            ),
            sortBy: (row: User): string => row.SSO?.name,
            textValue: (row: User): string => row.SSO?.name,
            valueToExport: (row: User): string => row.SSO?.name
        },
        {
            header: this.translate.instant("CREATED_AT"),
            columnDef: "created_at",
            width: 280,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<User>(
                row => (row.created_at ? this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a") : "-"),
                row => (row.created_at ? this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a") : "-"),
                () => true
            ),
            sortBy: (row: User) => new Date(row.created_at).getTime(),
            textValue: (row: User): string =>
                row.created_at ? this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a") : "-",
            valueToExport: (row: User): string => row.created_at
        },
        {
            header: this.translate.instant("LAST_LOGIN"),
            columnDef: "last_login_at",
            width: 280,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<User>(
                row => (row.last_login_at ? this.datePipe.transform(row.last_login_at, "MMM d, y, h:mm:ss a") : "-"),
                row => (row.last_login_at ? this.datePipe.transform(row.last_login_at, "MMM d, y, h:mm:ss a") : "-"),
                () => true
            ),
            sortBy: (row: User) => new Date(row.last_login_at).getTime(),
            textValue: (row: User): string =>
                row.last_login_at ? this.datePipe.transform(row.last_login_at, "MMM d, y, h:mm:ss a") : "-",
            valueToExport: (row: User): string => row.last_login_at
        },
        {
            header: this.translate.instant("ACCOUNT_ADMINISTRATOR"),
            columnDef: "is_admin",
            width: 180,
            visible: true,

            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory(
                () => "check",
                () => "md",
                row => !!(row as unknown as KeyMap<User>)?.is_admin
            ),
            sortBy: (row: KeyMap<User>) => row?.is_admin,
            valueToExport: (row: User): string => (row?.is_admin ? "Yes" : "No")
        },
        {
            header: this.translate.instant("ADMINISTRATOR"),
            columnDef: "is_objects_manager",
            width: 180,
            visible: true,

            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory(
                () => "check",
                () => "md",
                row => !!(row as unknown as KeyMap<User>)?.is_objects_manager
            ),
            sortBy: (row: KeyMap<User>) => (row?.is_objects_manager ? 1 : 0),
            valueToExport: (row: User): string => (row?.is_objects_manager ? "Yes" : "No")
        },
        {
            header: this.translate.instant("ENABLED"),
            columnDef: "is_enabled",
            width: 180,
            visible: true,

            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory(
                () => "check",
                () => "md",
                row => !!(row as unknown as KeyMap<User>)?.is_enabled
            ),
            sortBy: (row: KeyMap<User>) => row?.is_enabled,
            valueToExport: (row: User): string => (row?.is_enabled ? "Yes" : "No")
        }
    ];

    private tourSteps = TourSteps.userList;

    constructor(
        private router: Router,
        private tps: UsersService,
        private modalService: ModalService,
        private usersService: UsersService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        public tourService: TourService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                this.titleService.setTitle("USERS", "");
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.selectedUserID = parseInt(event.snapshot.params.id, 10);
                } else {
                    // Set Title
                    this.selectedUserID = null;
                    this.selectedRows = [];
                }

                this.updateSelectedRows();
            });
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.usersService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.impersonating$ = this.usersService.user.pipe(map(u => u.impersonating));
        this.isZixiAdmin$ = this.usersService.user.pipe(map(u => u.is_zixi_admin));

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.usersSubscription = this.tps.users.subscribe(users => {
            this.users = users;
            this.updateSelectedRows();

            if (this.users) {
                this.usersBS$.next([...this.users]);
                this.loading = false;
            }
        });

        setTimeout(() => this.updateSelectedRows(), 0);

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
    }

    selectRow = (user: User) => {
        this.selectedUserID = user.id;
        this.router.navigate([Constants.urls.accountManagement.users, user.id]);
    };

    updateSelectedRows() {
        const existingUser = this.users.find(user => user.id === this.selectedUserID);
        this.selectedRows = existingUser ? [existingUser] : [];
    }

    async refresh() {
        this.refreshing = true;
        let user = null;
        const users = firstValueFrom(this.tps.refreshUsers(true));
        if (this.selectedUserID) {
            user = firstValueFrom(this.usersService.refreshUser(this.selectedUserID));
        }
        await Promise.all([users, user]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (user: User) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "USER", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple users");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (user: User) => this.usersService.deleteUser(user));
    }

    get users$() {
        return this.usersBS$.asObservable();
    }
}
